import styled from "styled-components"

export const HomeAnimationSwitchbackSectionWrapper = styled.div`
  position: relative;
  font-family: "Roboto";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 96px 80px 96px;
  max-height: 2200px;
  @media (max-width: 992px) {
    padding: 80px;
    max-height: 2700px;
  }
  @media (max-width: 768px) {
    padding: 80px 20px;
    max-height: 3200px;
  }
  .background-image {
    position: absolute;
    z-index: -1;
    height:100%;
    width: 100vw;
    left: 0;
    top: 0;
    img {
      height:100%;
      object-position: top;
      object-fit: unset!important;
    }
  }
  .home-howworks-section--copy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .home-howworks-section--heading {
      font-weight: 900;
      font-size: 50px;
      line-height: 60px;
      color: #1a2834;
      margin-bottom: 16px;
      @media (max-width: 576px) {
        font-size: 32px;
        line-height: 38px;
      }
    }
    .home-howworks-section--description {
      font-weight: normal;
      font-size: 22px;
      line-height: 30px;
      color: #1a2834;
      text-align: center;
      margin: 16px 0;
      max-width: 470px;
      @media (max-width: 576px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .way-one {
      display:none;
    }
  }
`

export const HowWorksItemWrapper = styled.div`
  min-height: 500px;
  max-width: 1167px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .home-howworks-section--arrow {
    max-width: 65%;
    margin: 16px 0;
    @media (max-width: 768px) {
      max-width: 80%;
    }
  }
  .home-howworks-section--item-content {
    display: flex;
    flex-direction: ${props => (props.index % 2 === 0 ? "row" : "row-reverse")};
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
    .home-howworks-section--item-lottie-container {
      flex: 5;
      margin: ${props => (props.index % 2 === 0 ? "0 64px 0 0" : "0 0 0 64px")};
      @media (max-width: 768px) {
        margin: 24px 0 0;
      }
      img {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
    .home-howworks-section--item-copy-container {
      flex: 6;
      max-width: 570px;
      position: relative;
      @media (max-width: 992px) {
        padding: 48px 0;
      }
      @media (max-width: 768px) {
        padding: 64px 0 0 20px;
        max-width: 100%;
      }
      &:before {
        content: ${props => `"${props.index + 1}"`};
        position: absolute;
        color: #faeae9;
        font-style: normal;
        font-weight: 900;
        font-size: 180px;
        line-height: 72px;
        z-index: 1;
        left: -56px;
        top: -26px;
        @media (max-width: 992px) {
          top: 40px;
        }
        @media (max-width: 768px) {
          left: -12px;
        }
      }
      & > * {
        z-index: 2;
        position: relative;
      }
      h6 {
        font-weight: 900;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #002dc2;
      }
      h2 {
        font-weight: bold;
        font-size: 38px;
        line-height: 42px;
        color: #1b1b1b;
        margin: 16px 0 0;
        @media (max-width: 576px) {
          font-size: 30px;
          line-height: 38px;
        }
      }
      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #4d4d4d;
        margin: 16px 0 0;
      }
    }
  }
`