import React, { useState, useEffect } from "react"
import Lottie from "react-lottie"
import integrateAni from "/static/lotties/integrate-hp.json"
import buildAni from "/static/lotties/build-hp.json"
import deliverAni from "/static/lotties/deliver-updated.json"
import { Waypoint } from "react-waypoint"
import { switchbackResolveRichText } from "@utils/resolve-rich-text/switchbackResolveRichText"
import {
  HomeAnimationSwitchbackSectionWrapper,
  HowWorksItemWrapper,
} from "./styles/SwitchbackHome.styled"
import OptimizedImage from "../../utils/optimizedImage"
import fetchLottie from "../../utils/fetchLottie"

const SwitchbackHome = ({ component }) => {
  const {
    heading,
    subheading,
    animation1Heading,
    animation1,
    animation1File,
    animation2Heading,
    animation2,
    animation2File,
    animation3Heading,
    animation3,
    animation3File,
    animation1Kicker,
    animation2Kicker,
    animation3Kicker,
    featuredImage,
  } = component

  let [renderIntegrate, setRenderIntegrate] = useState(false)
  let [renderDeliver, setRenderDeliver] = useState(false)
  const [lottie1, setLottie1] = useState()
  const [lottie2, setLottie2] = useState()
  const [lottie3, setLottie3] = useState()

  useEffect(() => {
    if (animation1File?.file?.url?.includes("json")) {
      fetchLottie(animation1File?.file?.url, setLottie1)
    }
    if (animation2File?.file?.url?.includes("json")) {
      fetchLottie(animation2File?.file?.url, setLottie2)
    }
    if (animation3File?.file?.url?.includes("json")) {
      fetchLottie(animation3File?.file?.url, setLottie3)
    }
  }, [animation1File, animation2File, animation3File])

  const integrateOptions = {
    loop: true,
    autoplay: true,
    animationData: lottie1 ?? integrateAni,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  const buildOptions = {
    loop: true,
    autoplay: true,
    animationData: lottie2 ?? buildAni,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  const deliverOptions = {
    loop: true,
    autoplay: true,
    animationData: lottie3 ?? deliverAni,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <HomeAnimationSwitchbackSectionWrapper>
      <OptimizedImage
        className="background-image"
        image={featuredImage?.gatsbyImageData}
        src={featuredImage?.file?.url}
        alt=""
      />
      <Waypoint className="way-one" onEnter={() => setRenderIntegrate(true)} />
      <div className="home-howworks-section--container">
        <div className="home-howworks-section--copy-container">
          <h2 className="home-howworks-section--heading">{heading}</h2>
          {subheading && (
            <p className="home-howworks-section--description">
              {switchbackResolveRichText(subheading)}
            </p>
          )}
        </div>
        <div className="home-howworks-section--content-container">
          <HowWorksItemWrapper index={0}>
            <img
              className="home-howworks-section--arrow"
              src={`/images/home-howworks-arrow-1.svg`}
              alt="arrow to next section"
              loading="lazy"
            />
            <div className="home-howworks-section--item-content">
              <div className="home-howworks-section--item-lottie-container">
                {renderIntegrate && <Lottie options={integrateOptions} />}
              </div>
              <div className="home-howworks-section--item-copy-container">
                <h6>{animation1Kicker}</h6>
                <h2>{animation1Heading}</h2>
                <p>{switchbackResolveRichText(animation1)}</p>
              </div>
            </div>
          </HowWorksItemWrapper>
          <HowWorksItemWrapper index={1}>
            <img
              className="home-howworks-section--arrow"
              src={`/images/home-howworks-arrow-2.svg`}
              alt="arrow to next section"
              loading="lazy"
            />
            <div className="home-howworks-section--item-content">
              <div className="home-howworks-section--item-lottie-container">
                <Lottie options={buildOptions} />
              </div>
              <div className="home-howworks-section--item-copy-container">
                <h6>{animation2Kicker}</h6>
                <h2>{animation2Heading}</h2>
                <p>{switchbackResolveRichText(animation2)}</p>
              </div>
            </div>
          </HowWorksItemWrapper>
          <HowWorksItemWrapper index={2}>
            <Waypoint
              className="way-one"
              onEnter={() => setRenderDeliver(true)}
            />
            <img
              className="home-howworks-section--arrow"
              src={`/images/home-howworks-arrow-3.svg`}
              alt="arrow to next section"
              loading="lazy"
            />
            <div className="home-howworks-section--item-content">
              <div className="home-howworks-section--item-lottie-container">
                {renderDeliver && <Lottie options={deliverOptions} />}
              </div>
              <div className="home-howworks-section--item-copy-container">
                <h6>{animation3Kicker}</h6>
                <h2>{animation3Heading}</h2>
                <p>{switchbackResolveRichText(animation3)}</p>
              </div>
            </div>
          </HowWorksItemWrapper>
        </div>
      </div>
    </HomeAnimationSwitchbackSectionWrapper>
  )
}

SwitchbackHome.displayName = "SwitchbackHome"

export default SwitchbackHome
